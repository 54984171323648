import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import LoadingComponent from '@shared/components/loading/loading.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LoadingComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'cg-admin-panel';
  isLoading = true;
  constructor(private _authService: AuthService) {
    _authService.login()
  }


  get authenticated(): boolean {
    return this._authService.authenticated
  }
}
