import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoadingService } from '@core/services/loading.service';
import { finalize } from 'rxjs';

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingService: LoadingService = inject(LoadingService)
  loadingService.updateLoading(true);
  return next(req).pipe(
    finalize(() => {
      loadingService.updateLoading(false);
    }))
};
