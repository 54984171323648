import { Injectable } from "@angular/core";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  authConfig: AuthConfig = environment.authConfig

  constructor(private oauthService: OAuthService, private _router: Router, private _activateRouter: ActivatedRoute) { }

  get authenticated(): boolean {
    let hasIdToken = this.oauthService.hasValidIdToken();
    let hasAccessToken = this.oauthService.hasValidAccessToken();
    return (hasIdToken && hasAccessToken)
  }

  clearToken() {
    this.oauthService.revokeTokenAndLogout()
  }

  getToken(): string | null {
    return this.oauthService.getAccessToken()
  }

  login() {
    this.configure();
  }

  logout() {
    this.oauthService.stopAutomaticRefresh();
    this.oauthService.revokeTokenAndLogout();
  }

  private configure() {
    this.oauthService.configure(this.authConfig);
    this.oauthService.loadDiscoveryDocumentAndLogin().then((res: boolean) => {
      let hasIssuer: boolean = this._activateRouter.snapshot.queryParamMap.has('iss')
      if (res && hasIssuer) {
        this._router.navigate(['/'])
      }
    });

    this.oauthService.setupAutomaticSilentRefresh();
  }
}
