import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { AlertService } from '@shared/services/alert.service';
import { catchError, throwError } from 'rxjs';

export const errorHandlingInterceptor: HttpInterceptorFn = (req, next) => {
  const alertService = inject(AlertService)
  const authService = inject(AuthService)
  const router = inject(Router)
  return next(req).pipe(catchError(
    (error: HttpErrorResponse) => {
      switch (error.status) {
        case 400:
          errorHandling(error, alertService)
          return throwError(() => error)

        case 401:
          errorHandling(error, alertService, 'unauthorized')
          // authService.clearToken();
          return throwError(() => error)

        case 403:
          errorHandling(error, alertService, "Access Denied")
          accessDenied(router)
          return throwError(() => error)

        case 404:
          errorHandling(error, alertService)
          return throwError(() => error)

        case 409:
          errorHandling(error, alertService)
          return throwError(() => error)

        case 499:
          errorHandling(error, alertService)
          return throwError(() => error)

        case 500:
          errorHandling(error, alertService)
          return throwError(() => error)
      }


      return throwError(() => error)
    }))

};

const errorHandling = (error: HttpErrorResponse, alertService: AlertService, text: string = 'Operation Failed') => {
  const messages = (error.error.messages ?? null) as any[] | null

  if (messages === null) {
    alertService.failedAlert(text)
    return
  }

  messages.forEach((message) => {
    alertService.failedAlert(message.description ?? text)
  })

}

const accessDenied = (router: Router) => {
  router.navigate(['access-denied'])
}
