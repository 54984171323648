import { Routes } from '@angular/router';
import { authLoginGuard } from '@core/guards/auth.login.guard';
import { permissionsGuard } from '@core/guards/permissions.guard';
import { LayoutComponent } from '@shared/layout/layout.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [authLoginGuard],
    children: [
      {
        path: 'terminal-customer', loadChildren: () => import('./pages/terminal-customer/terminal-customer.routes'),
        canActivate: [permissionsGuard],
        data: {
          permissions: ['pay_terminal_customer_view']
        }
      },
      {
        path: 'merchant', loadChildren: () => import('./pages/merchant/merchant.routes'),
        canActivate: [permissionsGuard],
        data: {
          permissions: ['ipg_merchant_view']
        }
      },
      {
        path: 'terminal-cgpay', loadChildren: () => import('./pages/terminal-cg-pay/terminal-cg-pay.routes'),
        canActivate: [permissionsGuard],
        data: {
          permissions: ['cgpay_terminal_view']
        }
      },
      {
        path: 'transactions', loadChildren: () => import('./pages/transactions/transactions.routes'),
        canActivate: [permissionsGuard],
        data: {
          permissions: ['transaction_payin_view', 'transaction_payout_view']
        }
      },
      {
        path: 'wallet', loadChildren: () => import('./pages/wallet/wallet.routes'),
        canActivate: [permissionsGuard],
        data: {
          permissions: ['']
        }
      },
      {
        path: 'wallet-address', loadChildren: () => import('./pages/wallet-address/wallet-address.routes'),
        canActivate: [permissionsGuard],
        data: {
          permissions: ['wallet_address_view']
        }
      },
      {
        path: 'webhook-log', loadChildren: () => import('./pages/webhook-log/webhook-log.routes'),
        canActivate: [permissionsGuard],
        data: {
          permissions: ['webhook_management_view']
        }
      },
      {
        path: 'access-denied', loadComponent: () => import('./shared/components/access-denied/access-denied.component')
      },
      {
        path: 'ipg', loadChildren: () => import('./pages/ipg/ipg.routes'),
        canActivate: [permissionsGuard],
        data: {
          permissions: ['ipg_report_info', 'ipg_report_asset_balance']
        }
      },
      {
        path: 'crypto-settings', loadChildren: () => import('./pages/crypto-settings/crypto-settings.routes'),
        canActivate: [permissionsGuard],
        data: {
          permissions: [
            'crypto_currency_blockchain_network_view',
            'crypto_currency_blockchain_network_currency_view',
            'crypto_currency_crypto_provider_view',
            'crypto_currency_crypto_provider_api_account_view',
            'crypto_currency_crypto_wallet_view']
        }
      },
      {
        path: 'configuration', loadChildren: () => import('./pages/configuration/configuration.routes'),
        canActivate: [permissionsGuard],
        data: {
          permissions: ['crypto_currency_view']
        }
      },
      {
        path: 'user-management', loadChildren: () => import('./pages/user-management/user-management.routes'),
        canActivate: [permissionsGuard],
        data: {
          permissions: ['idp_view_manager_list', 'idp_view_role_list', 'idp_view_customers']
        }
      },


    ]
  },

  {
    path: '**', redirectTo: '/'
  }
];
