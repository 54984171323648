import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';

export const authLoginGuard: CanActivateFn = (): UrlTree | boolean => {
  if (inject(AuthService).authenticated)
    return true;
  else
    return false;
}; 
