<div class="section-breadcrumb">
  <div class="breadcrumb_container">
    <div class="form_name">
      @if (showBackLocationButton) {
      <i class="fa-regular fa-arrow-left" gcLocationBack></i>
      }
      {{ breadcrumbs.at(-1)?.formName}}
    </div>
    <div class="breadcrumb-list">
      <a class="breadcrumb-item has-link" [routerLink]="'/'"><i class="fa-solid fa-house"></i></a>
      @for (item of breadcrumbs; track $index ) {
      <ng-container>
        @if (item.url !== '') {
        <a class="breadcrumb-item has-link" [routerLink]="item.url">{{ item.label }}</a>
        }
        @else if (item.url === '') {
        <span class="breadcrumb-item">{{ item.label }}</span>
        }
      </ng-container>
      }
    </div>
  </div>
</div>