import { inject, Injectable, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResendConfirmationDialogComponent } from '../components/resend-confirmation-dialog/resend-confirmation-dialog.component';
import { BaseService } from '@core/services/base.service';
import { IBaseMapper } from '@shared/model/mapper-model';
import { webhookLogMapper } from '../mapper/webhook-log.mapper';
import { Observable } from 'rxjs';
import { IReSendResponse } from '../models/resend-response.model';

@Injectable({
  providedIn: 'root'
})
export class WebhookLogService extends BaseService {
  reSendStatus = signal<'new' | 'pending' | 'complete' | 'failed'>('new')
  resendResponse: IReSendResponse = {
    'body': { name: 'test', status: 200, time: '10/29/2024', isFailed: false }, 'header': { name: 'test headers', time: '10/30/2024', token: 123 },
    status: 'Success', statusCode: 200, date: '2024-10-29T11:01:15.997Z'
  }
  override baseMapper = new webhookLogMapper();

  openResendConfirmationDialog(requestId: string, needShowResponse = true) {
    const dialogService = inject(MatDialog)
    dialogService.open(ResendConfirmationDialogComponent, {
      disableClose: true, data: { showResponse: needShowResponse, requestId },
      minWidth: '500px', autoFocus: false
    })
  }

  resend(requestId: string) {
    this.postItem<unknown, unknown>('', { data: {}, context: '' }).subscribe({
      next: (response) => {
        this.reSendStatus.set('complete')
        if (response)
          this.resendResponse = response as IReSendResponse
      },
      error: () => {
        this.reSendStatus.set('failed')
      }
    })
  }
}
