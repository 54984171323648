import { Routes } from '@angular/router';
import { authLoginGuard } from '@core/guards/auth.login.guard';
import { LayoutComponent } from '@shared/layout/layout.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'terminal-customer', loadChildren: () => import('./pages/terminal-customer/terminal-customer.routes'), canActivate: [authLoginGuard]
      },
      {
        path: 'merchant', loadChildren: () => import('./pages/merchant/merchant.routes'), canActivate: [authLoginGuard]
      },
      {
        path: 'terminal-cgpay', loadChildren: () => import('./pages/terminal-cg-pay/terminal-cg-pay.routes'), canActivate: [authLoginGuard]
      },
      {
        path: 'transactions', loadChildren: () => import('./pages/transactions/transactions.routes'), canActivate: [authLoginGuard]
      },
      {
        path: 'wallet', loadChildren: () => import('./pages/wallet/wallet.routes'), canActivate: [authLoginGuard]
      },
      {
        path: 'wallet-address', loadChildren: () => import('./pages/wallet-address/wallet-address.routes'), canActivate: [authLoginGuard]
      },
      {
        path: 'webhook-log', loadChildren: () => import('./pages/webhook-log/webhook-log.routes'), canActivate: [authLoginGuard]
      },
      {
        path: 'access-denied', loadComponent: () => import('./shared/components/access-denied/access-denied.component')
      },
      {
        path: 'ipg', loadChildren: () => import('./pages/ipg/ipg.routes'), canActivate: [authLoginGuard]
      }
    ]
  },

  {
    path: '**', redirectTo: '/'
  }
];
