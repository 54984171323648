import { IApiResponse, IData } from "@core/models/api.response"
import { IBaseMapper } from "@shared/model/mapper-model"
import { IMapperResponse } from "@shared/model/mapper-response-model"

export class webhookLogMapper implements IBaseMapper<any, any, any, any> {
    fetchList = (data: IData<any>): IMapperResponse<any> => {
        return {
            count: data!.base.total || 0,
            data: data!.items.map((m: any) => {
                return {

                } as any
            })
        }
    }

    fetchGetById = (data: any): any => {
        return {

        } as any
    }
}