import { Component, effect, inject, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { WebhookLogService } from '../../services/webhook-log.service';
import { PrettyJsonPipe } from '@shared/pipes/pretty-json.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgClass } from '@angular/common';
import { IReSendResponse } from '../../models/resend-response.model';

@Component({
  selector: 'cg-resend-confirmation-dialog',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, PrettyJsonPipe, ClipboardModule, NgClass],
  templateUrl: './resend-confirmation-dialog.component.html',
  styleUrl: './resend-confirmation-dialog.component.scss'
})
export class ResendConfirmationDialogComponent implements OnDestroy {
  readonly dialogRef = inject(MatDialogRef<ResendConfirmationDialogComponent>);
  readonly data = inject<{ showResponse: boolean, requestId: string }>(MAT_DIALOG_DATA);
  webHookLogService = inject(WebhookLogService)
  requestResponse!: IReSendResponse
  resendStatusEffect = effect((() => {
    this.checkResendStatus(this.webHookLogService.reSendStatus())
  }))
  step = 1
  title = 'Confirm Resend'
  closeButtonLabel = 'Not Sure'

  reSend() {
    this.step = 2
    this.title = 'Resending ...'
    this.closeButtonLabel = 'Continue in Background'
    this.webHookLogService.resend(this.data.requestId)
  }

  convertJsonToString(json: JSON): string {
    return JSON.stringify(json)
  }

  formatDate(date: string): string {
    const convertedDate = new Date(date)
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };

    return new Intl.DateTimeFormat('en-US', options).format(convertedDate);
  }

  private checkResendStatus(status: "new" | "pending" | "complete" | "failed") {
    if (status === 'complete') {
      if (this.data.showResponse) {

        this.title = 'Resend Event Details'
        this.closeButtonLabel = 'Close'
        this.requestResponse = this.webHookLogService.resendResponse
        this.step = 3

        return
      }

      this.dialogRef.close(true)
      return
    }

    if (status === 'failed') {
      this.dialogRef.close(true)
      return
    }

  }

  ngOnDestroy(): void {
    this.resendStatusEffect && this.resendStatusEffect.destroy()
  }
}
