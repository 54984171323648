import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, Scroll } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { BreadCrumb } from './bread-crumb';
import { CommonModule } from '@angular/common';
import { LocationBackDirective } from '@shared/directives/location-back.directive';


@Component({
    selector: 'cg-bread-crumb',
    templateUrl: './bread-crumb.component.html',
    styleUrls: ['./bread-crumb.component.scss'],
    imports: [CommonModule, RouterModule, LocationBackDirective],
    encapsulation: ViewEncapsulation.None
})
export class BreadCrumbModule implements OnInit, OnDestroy {

	HOME = 'Home';
	showBackLocationButton: boolean = false
	public breadcrumbs: BreadCrumb[] = [];
	private isDestroyed$: Subject<void> = new Subject<void>();

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router
	) { }

	ngOnInit(): void {
		this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);

		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd ||
				(event instanceof Scroll && event.routerEvent instanceof NavigationEnd)),
			distinctUntilChanged(),
			takeUntil(this.isDestroyed$)
		).subscribe(() => {
			this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
		});

	}

	ngOnDestroy(): void {
		this.isDestroyed$.next();
		this.isDestroyed$.complete();
	}

	private createBreadcrumbs(route: ActivatedRoute, url = '', breadcrumbs: BreadCrumb[] = []): BreadCrumb[] {
		const children: ActivatedRoute[] = route.children;

		if (children.length === 0) {
			return breadcrumbs;
		}

		for (const child of children) {
			this.checkBackUrl(child)
			const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
			if (routeURL !== '') {
				url += `/${routeURL}`;
			}

			const breadcrumbData = child.snapshot.data['breadcrumb'];
			const formName = child.snapshot.data['formName']; // Extracting formName

			if (breadcrumbData) {
				if (Array.isArray(breadcrumbData)) {
					breadcrumbData.forEach(label => {
						breadcrumbs.push({ label, url, formName }); // Include formName
					});
				} else {
					breadcrumbs.push({ label: breadcrumbData, url, formName }); // Include formName
				}
			}

			return this.createBreadcrumbs(child, url, breadcrumbs);
		}
		return breadcrumbs;
	}

	private checkBackUrl(child: ActivatedRoute) {
		if (child.snapshot && child.snapshot.data['backUrl']) {
			this.showBackLocationButton = true

			return
		}

		this.showBackLocationButton = false
	}
}
