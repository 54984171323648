export interface MenuItem {
  id?: string;
  route: string;
  icon: string;
  name: string;
  summary?: string;
  children: MenuItem[];
}

export const ALL_ITEMS: MenuItem[] = [
  {
    route: '/route',
    icon: 'fa-regular fa-house',
    name: 'Dashboard',
    children: []
  },
  {
    route: '/route',
    icon: 'far fa-hand-paper',
    name: 'Reports',
    children: [
      {
        route: '/route',
        icon: '',
        name: 'Crypto Wallet',
        children: [
          // {
          //   route: '/wallet',
          //   icon: '',
          //   name: 'Wallet',
          //   children: []
          // },
          {
            route: '/wallet-address',
            icon: '',
            name: 'Wallet Address',
            children: []
          },
          {
            route: '/route',
            icon: '',
            name: 'Transactions',
            children: [
              {
                route: '/transactions/pay-in',
                icon: '',
                name: 'Pay-In',
                children: []
              },
              {
                route: '/transactions/pay-out',
                icon: '',
                name: 'Pay-Out',
                children: []
              }
            ]
          }]
      }
    ]
  },
  {
    route: '/route',
    icon: 'fa-regular fa-server',
    name: 'Api',
    children: [
      {
        route: '/route',
        icon: '',
        name: 'Payment Getway',
        children: [
          {
            route: '/merchant',
            icon: '',
            name: 'Merchant',
            children: []
          },
          {
            route: '/route',
            icon: '',
            name: 'Terminal',
            children: [
              {
                route: '/terminal-cgpay',
                icon: '',
                name: 'CGPay',
                children: []
              },
              {
                route: '/route',
                icon: '',
                name: 'B2B',
                children: []
              },
            ]
          },
          {
            route: '/terminal-customer',
            icon: '',
            name: 'Terminal Customer',
            children: []
          },
        ]
      },
    ]
  }
];
