<mat-nav-list [ngClass]="{'list-is-open': expandedElement === menu()}">
    <a (isActiveChange)="isActiveChange()" mat-list-item [routerLink]="menu().children.length ? null : menu().route"
        [style.padding-left]="index() + 8 + 'px'" routerLinkActive="item-selected" aria-current="page"
        (click)="changeMenuState()">
        <span class="menu-icon">
            <i [class]="menu().icon || 'dot-icon'"></i>
        </span>
        {{menu().name}}
        @if(menu().children.length){
        <span class="chevron" [ngClass]="{'rotate': expandedElement === menu()}">
            <i class="fa-regular fa-angle-right"></i>
        </span>
        }
    </a>
    @for (children of menu().children; track children.route) {
    <mat-nav-list [@bodyExpansion]="expandedElement === menu() ? 'expanded' : 'collapsed'">
        <app-nav-item [menu]="children" [index]="index() +12" [parentMenu]="menu()"
            [parentMenuIsOpen]="expandedElement === menu()" />
    </mat-nav-list>
    }
</mat-nav-list>