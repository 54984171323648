<div class="dialog">

    <div class="title">
        {{title}}
    </div>
    <mat-dialog-content align="center">
        @if (step === 1) {
        <span class="w-60 block">
            Are you sure you want to resend the webhook call?
        </span>
        }
        @else if (step === 2) {
        <div class="pending">
            <div class="w-200 h-200 mb-10">
                <div class="loading">
                    <span class="loader">
                    </span>
                </div>
            </div>
            <div>
                Please wait to resend webhook call ...
            </div>
        </div>
        }
        @else {
        <div class="response">
            <div class="detail">
                <div class="badges">
                    <div class="badge"
                        [ngClass]="{'bg-success' : requestResponse.status === 'Success' , 'bg-danger' : requestResponse.status !== 'Success'}">
                        {{requestResponse.status}}
                    </div>
                    <div class="badge"
                        [ngClass]="{'bg-success' : requestResponse.statusCode >= 200 && requestResponse.statusCode < 400 , 'bg-danger' : requestResponse.statusCode >= 400}">
                        {{requestResponse.statusCode}}
                    </div>
                    <div class="badge bg-danger">
                        Manually
                    </div>
                </div>
                <div>
                    {{formatDate(requestResponse.date)}}
                </div>
            </div>
            <div class="b-bottom">
                <div class="request-response">
                    <div class="header">
                        <div>Headers</div>
                        <div>
                            <button mat-button type="button" class="border"
                                [cdkCopyToClipboard]="convertJsonToString(requestResponse.header)">Copy
                                <i class="fa-regular fa-copy"></i>
                            </button>
                        </div>
                    </div>
                    <div class="body">
                        <pre [innerHTML]="requestResponse.header | prettyjson : [true, 3]">
                        </pre>
                    </div>
                </div>
                <div class="request-response">
                    <div class="header">
                        <div>Body</div>
                        <div>
                            <button mat-button type="button" class="border"
                                [cdkCopyToClipboard]="convertJsonToString(requestResponse.body)">Copy
                                <i class="fa-regular fa-copy"></i>
                            </button>
                        </div>
                    </div>
                    <div class="body">
                        <pre [innerHTML]="requestResponse.body | prettyjson : [true, 3]">
                        </pre>
                    </div>
                </div>
            </div>
        </div>
        }
    </mat-dialog-content>
    <mat-dialog-actions align="center">

        <button mat-button type="button" mat-dialog-close color="primary"
            class="w-120 mr-16 border">{{closeButtonLabel}}</button>
        @if (step === 1) {
        <button mat-raised-button type="button" color="primary" class="w-120 text-white" (click)="reSend()">Yes</button>
        }
    </mat-dialog-actions>
</div>