import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDrawerToggleResult, MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Observable, Subscription, filter, map } from 'rxjs';
import { NavComponent } from './nav/nav.component';
import { BreadCrumbModule } from '../components/bread-crumb/bread-crumb.component';
import { HeaderComponent } from './header/header.component';

const SMALL_WIDTH_BREAKPOINT = 959;

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    AsyncPipe,
    RouterOutlet,
    MatSidenavModule,
    NavComponent,
    BreadCrumbModule,
    HeaderComponent
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  readonly navigationEndEvents = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

  private subscriptions = new Subscription();
  isScreenSmall: Observable<boolean>;

  constructor(
    breakpoints: BreakpointObserver,
    private router: Router
  ) {
    this.isScreenSmall = breakpoints.observe(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`)
      .pipe(map(breakpoint => breakpoint.matches));
  }

  ngOnInit() {
    this.subscriptions.add(
      this.navigationEndEvents.pipe(map(() => this.isScreenSmall))
        .subscribe((shouldCloseSideNav) => {
          if (shouldCloseSideNav && this.sidenav) {
            this.sidenav.close();
          }
        })
    );
  }

  toggleSidenav(): Promise<MatDrawerToggleResult> {
    return this.sidenav?.toggle();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
