<cg-header class="z-500" />
<mat-sidenav-container class="sidenav-container">
    @if (isScreenSmall | async) {
    <mat-sidenav #sidenav [opened]="(isScreenSmall | async) === false"
        [mode]="(isScreenSmall | async) ? 'over' : 'side'" [fixedInViewport]="(isScreenSmall | async)"
        [fixedTopGap]="64">
        <app-nav />
    </mat-sidenav>
    }
    <div class="sidenav-content">
        <div class="sidenav-inner-content">
            <main class="sidenav-body-content">
                @if ((isScreenSmall | async) === false) {
                <app-nav />
                }
                <div class="content">
                    <cg-bread-crumb></cg-bread-crumb>
                    <router-outlet />
                </div>

            </main>
            <!-- <app-footer /> -->
        </div>
    </div>
</mat-sidenav-container>