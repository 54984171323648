import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ErrorInterceptor } from '@core/interceptors/http.interceptor';
import { JwtInterceptor } from '@core/interceptors/jwt.interceptor';
import { UnauthorizedInterceptor } from '@core/interceptors/unauthorized.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { loadingInterceptor } from '@core/interceptors/loading.interceptor';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { errorHandlingInterceptor } from '@core/interceptors/error-handling.interceptor';
import { provideToastr } from 'ngx-toastr';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes, withComponentInputBinding()),
  importProvidersFrom(
    BrowserModule,
    BrowserAnimationsModule,
  ), provideHttpClient(withInterceptors([JwtInterceptor, loadingInterceptor, UnauthorizedInterceptor, ErrorInterceptor, errorHandlingInterceptor])),
  provideOAuthClient(), provideAnimations(), provideToastr()]
};
