<mat-toolbar>
  <mat-toolbar-row>
    <div class="menu-container">
      <div class="menu-container__logo">
          <img ngSrc="assets/images/logo/logo-coinigo-navbar.svg" alt="Logo" height="40" width="136">
      </div>
      <div class="menu-container__actions">
        <div class="menu-container_search_container">
          <input type="text" id="searchInput" placeholder="Search" />
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>
        <a>Open Website</a>
        <i class="fa-regular fa-user"></i>
        <i class="fa-regular fa-bell"></i>
        <i class="fa-solid fa-arrow-right-from-bracket" (click)="logout()"></i>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
