import {CommonModule, NgOptimizedImage} from '@angular/common';
import {Component, input, output, ViewEncapsulation} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthService } from '@core/services/auth.service';

@Component({
    selector: 'cg-header',
    imports: [CommonModule, MatToolbarModule, MatIconModule, MatMenuModule, NgOptimizedImage],
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {

  withSearchbar = input<boolean>(false)
  user = input<{ username: string; avatar: string }>()
  links = input()

  searchValue = input<string>()
  searchValueChange = output<string>()

  constructor(private _authService: AuthService,) { }

  logout() {
    this._authService.logout()
  }

  private defaultLinks() {
    return [
      {
        label: 'Tours',
        url: '/tours/cyprus',
      },
      {
        label: 'Checkout',
        url: '/checkout/1',
      },
      {
        label: 'Travel Guides',
        url: '#',
      },
      {
        label: 'About us',
        url: '#',
      },
    ];
  }
}
