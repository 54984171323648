export interface MenuItem {
  id?: string;
  route: string;
  icon: string;
  name: string;
  summary?: string;
  children: MenuItem[];
}

export const ALL_ITEMS: MenuItem[] = [
  {
    route: '/route1',
    icon: 'fa-regular fa-house',
    name: 'Dashboard',
    children: []
  },
  {
    route: '/route2',
    icon: 'far fa-hand-paper',
    name: 'Reports',
    children: [
      {
        route: '/route3',
        icon: '',
        name: 'Crypto Wallet',
        children: [
          // {
          //   route: '/wallet',
          //   icon: '',
          //   name: 'Wallet',
          //   children: []
          // },
          {
            route: '/wallet-address',
            icon: '',
            name: 'Wallet Address',
            children: []
          },
          {
            route: '/route4',
            icon: '',
            name: 'Transactions',
            children: [
              {
                route: '/transactions/pay-in',
                icon: '',
                name: 'Pay-In',
                children: []
              },
              {
                route: '/transactions/pay-out',
                icon: '',
                name: 'Pay-Out',
                children: []
              }
            ]
          }]
      },
      {
        route: '/ipg',
        icon: '',
        name: 'IPG',
        children: []
      }
    ]
  },
  {
    name: 'Crypto Settings',
    route: '/route10',
    icon: 'fa-regular fa-globe',
    children: [
      {
        name: 'Crypto Wallet',
        icon: '',
        route: '/crypto-settings/wallet',
        children: []
      },
      {
        name: 'Blockchain',
        route: '/route15',
        icon: '',
        children: [
          {
            name: 'Network',
            icon: '',
            route: '/crypto-settings/blockchain/network',
            children: []
          },
          {
            name: 'Network Currency',
            icon: '',
            route: '/crypto-settings/blockchain/network-currency',
            children: []
          }
        ]
      },
      {
        name: 'Crypto Provider',
        route: '/route12',
        icon: '',
        children: [
          {
            name: 'Provider',
            icon: '',
            route: '/crypto-settings/provider',
            children: []
          },
          {
            name: 'Provider Account',
            icon: '',
            route: '/crypto-settings/provider-account',
            children: []
          }
        ]
      },
    ]
  },
  {
    name: 'Configuration',
    route: '/routes15',
    icon: 'fa-regular fa-sliders',
    children: [
      {
        name: 'Currencies',
        icon: '',
        children: [],
        route: '/configuration/currencies'
      }
    ]
  },
  {
    route: '/route59',
    icon: 'fa-regular fa-user-gear',
    name: 'User Management',
    children: [
      {
        route: '/user-management/customer',
        icon: '',
        name: 'Customer',
        children: []
      },
      {
        route: '/route55',
        icon: '',
        name: 'Expert',
        children: [
          {
            route: '/user-management/expert/expert-management',
            icon: '',
            name: 'Expert List',
            children: []
          },
          {
            route: '/user-management/expert/role-management',
            icon: '',
            name: 'Roles Management ',
            children: []
          }
        ]
      }
    ]
  },
  {
    route: '/route5',
    icon: 'fa-regular fa-server',
    name: 'Api',
    children: [
      {
        route: '/route6',
        icon: '',
        name: 'Payment Getway',
        children: [
          {
            route: '/merchant',
            icon: '',
            name: 'Merchant',
            children: []
          },
          {
            route: '/route7',
            icon: '',
            name: 'Terminal',
            children: [
              {
                route: '/terminal-cgpay',
                icon: '',
                name: 'CGPay',
                children: []
              },
              {
                route: '/route8',
                icon: '',
                name: 'B2B',
                children: []
              },
            ]
          },
          {
            route: '/terminal-customer',
            icon: '',
            name: 'Terminal Customer',
            children: []
          },
        ]
      },
    ]
  },
  {
    route: '/route9',
    icon: 'fa-regular fa-code',
    name: 'Dev Space',
    children: [
      {
        route: '/webhook-log',
        icon: '',
        name: 'Webhook Log',
        children: []
      }]
  }
];
