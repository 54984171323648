import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AlertService } from '@shared/services/alert.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const ErrorInterceptor: HttpInterceptorFn = (request, next) => {
  const alertService = inject(AlertService)
  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMessage = 'An error occurred';

      // if (error.error instanceof ErrorEvent) {
      //   // Client-side error
      //   errorMessage = `Client-side error: ${error.error.message}`;
      // } else {
      //   // Server-side error
      //   errorMessage = `Server-side error (status code ${error.status}): ${error.message}`;
      // }
      // alertService.serverErrorAlert()
      return throwError(() => errorMessage);
    })
  );
}
