import { Component, ViewEncapsulation } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ALL_ITEMS } from '../services/menu-items';
import { NavItemComponent } from './components/nav-item/nav-item.component';


@Component({
  selector: 'app-nav',
  imports: [
    MatListModule,
    NavItemComponent,
  ],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class NavComponent {
  menuItems = ALL_ITEMS;
}
