import { Injectable, signal } from '@angular/core';
import { MenuItem } from './menu-items';

@Injectable({
  providedIn: 'root'
})
export class MenuService {


  expandedMenuElementSig = signal<{ menu: MenuItem, parentMenu: MenuItem | null } | null>(null)

  constructor() { }

  updateMenuState(value: { menu: MenuItem, parentMenu: MenuItem | null } | null): void {
    this.expandedMenuElementSig.set(value)
  }
}
